.photo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
}

.floating-photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: float 3s ease-in-out infinite;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.floating-photo-clicked {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: moveUp 0.3s forwards;
}

.floating-photo-clicked-moved {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: floatUp 3s ease-in-out infinite;
  cursor: pointer;
  position: relative;
}

.floating-photo-moved {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: moveDown 0.3s forwards;
}

.floating-photo-clicked+.links .link {
  opacity: 1;
}

.links {
  display: grid;
  position: absolute;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  text-align: center;
  color: white;
  gap: 50px;
  top: 45%;
  width: 150px;
}

.link-item {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation-name: fadeIn;
  animation-delay: 0.2s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.link-item img {
  width: 100%;
  object-fit: contain;
}

.link.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.container {
  display: grid;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 45%;
}

.video-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
}

.video-link {
  text-decoration: none;
  color: white;
  padding: 10px;
  border-radius: 5px;
  display: block;
}

.back-button {
  background: none;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
}

.about {
  display: grid;
  color: white;
  grid-template-columns: 1fr;
  gap: 10px;
  width: 100%;
  max-width: 600px;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
}

.about::-webkit-scrollbar {
  width: 8px;
}

.about::-webkit-scrollbar-thumb {
  background-color: #340d5f;
  border-radius: 10px;
}

.about::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes float {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

@keyframes floatUp {

  0%,
  100% {
    transform: translateY(-200px);
  }

  50% {
    transform: translateY(-210px);
  }
}


@keyframes moveUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-200px);
  }
}

@keyframes moveDown {
  0% {
    transform: translateY(-200px);
  }

  100% {
    transform: translateY(0);
  }
}