body {
  background: linear-gradient(180deg, #340d5f, #940d79);
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 50% 0%;
  }

  50% {
    background-position: 50% 100%;
  }

  100% {
    background-position: 50% 0%;
  }
}